import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { AppContext, AppContextState } from "../context/AppContext";

dayjs.extend(utc);
dayjs.extend(timezone);

interface AppProviderProps {
    children?: React.ReactNode;
}

const AppProvider = ({ children }: AppProviderProps): JSX.Element => {
    const getStandardDateStart = () => {
        return dayjs().startOf("month").subtract(1, "year");
    };

    const getStandardDateEnd = () => {
        return dayjs().endOf("day");
    };

    const [dateTimeSelectedStart, setDateTimeSelectedStart] = React.useState<Dayjs | null>(getStandardDateStart());
    const [dateTimeSelectedEnd, setDateTimeSelectedEnd] = React.useState<Dayjs | null>(getStandardDateEnd());
    const [clientTimezone, setClientTimezone] = React.useState<string>("Europe/Berlin");
    const [isFilterAvailable, setIsFilterAvailable] = React.useState<boolean>(false);
    const [headline, setHeadline] = useState<string | null>(null);

    useEffect(() => {
        dayjs.tz.setDefault("Europe/Berlin");
        setClientTimezone(dayjs.tz.guess());

        if (localStorage.getItem("use_standard_time") === "true") {
            setDateTimeSelectedStart(getStandardDateStart());
            setDateTimeSelectedEnd(getStandardDateEnd());
        } else {
            const localStorageDateTimeStart = localStorage.getItem("cw_cmp_date_from");
            if (localStorageDateTimeStart) {
                const dateTimeStart = dayjs(JSON.parse(localStorageDateTimeStart));
                setDateTimeSelectedStart(dateTimeStart);
            }

            const localStorageDateTimeEnd = localStorage.getItem("cw_cmp_date_till");
            if (localStorageDateTimeEnd) {
                const dateTimeEnd = dayjs(JSON.parse(localStorageDateTimeEnd));
                setDateTimeSelectedEnd(dateTimeEnd);
            }
        }

    }, []);


    const updateDateTimesSelected = (data: DateRange<dayjs.Dayjs>) => {
        const dateTimeStart = data[0];
        const dateTimeEnd = data[1];

        localStorage.setItem("use_standard_time", "false");

        setDateTimeSelectedStart(dateTimeStart);
        localStorage.setItem("cw_cmp_date_from", JSON.stringify(dateTimeStart));

        setDateTimeSelectedEnd(dateTimeEnd);
        localStorage.setItem("cw_cmp_date_till", JSON.stringify(dateTimeEnd));

    };

    const resetDateTimesSelected = () => {
        localStorage.setItem("use_standard_time", "true");

        setDateTimeSelectedStart(getStandardDateStart());
        localStorage.setItem("cw_cmp_date_from", JSON.stringify(getStandardDateStart()));

        setDateTimeSelectedEnd(getStandardDateEnd());
        localStorage.setItem("cw_cmp_date_till", JSON.stringify(getStandardDateEnd()));
    };


    const value: AppContextState = {
        dateTimeSelectedStart: dateTimeSelectedStart,
        dateTimeSelectedEnd: dateTimeSelectedEnd,
        clientTimezone: clientTimezone,
        filterEnabled: isFilterAvailable,
        setFilterEnabled: setIsFilterAvailable,
        updateDateTimesSelected: updateDateTimesSelected,
        resetDateTimesSelected: resetDateTimesSelected,
        getStandardDateStart: getStandardDateStart,
        getStandardDateEnd: getStandardDateEnd,
        headline: headline,
        setHeadline: setHeadline,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
