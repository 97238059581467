import Calendar from "@mui/icons-material/Event";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { SxProps, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {
    DateRange,
    DateTimeRangePicker,
    PickersActionBarProps,
    SingleInputDateTimeRangeField,
} from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";


const HeadlineFilterComponent: React.FC = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const {
        dateTimeSelectedStart,
        dateTimeSelectedEnd,
        updateDateTimesSelected,
        resetDateTimesSelected,
        headline,
        setHeadline,
        filterEnabled,
        setFilterEnabled,
    } = useAppContext();


    useEffect(() => {
        setHeadline(null);
        setFilterEnabled(false);
    }, [location, setHeadline, setFilterEnabled]);

    if (!headline && !filterEnabled) {
        return null;
    }


    const updateDateTimes = (data: DateRange<dayjs.Dayjs>) => {
        updateDateTimesSelected(data);
        enqueueSnackbar(t("snackbar_message_data_will_be_updated"), { variant: "success" });
    };

    const getBoxSx = () => {
        let sx: SxProps<Theme> = {};

        if (!isMobile) {
            sx = {
                ...sx,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "16px 0",
            };
        }

        return sx;
    };

    const getFieldSx = () => {

        let sx: SxProps<Theme> = {
            minWidth: "350px",
            maxWidth: "100%",
            my: 3,
            input: { color: theme.palette.text.secondary },
        };

        if (isMobile) {
            sx = { ...sx, width: "100%" };
        }

        return sx;
    };


    const customActionBar = (props: PickersActionBarProps) => {
        const { onAccept, onCancel } = props;
        return (
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    {t("filter_component_dialog_button_close")}
                </Button>
                <Button onClick={resetDateTimesSelected} color="secondary">
                    {t("filter_component_dialog_button_reset")}
                </Button>
                <Button onClick={onAccept} color="primary">
                    {t("filter_component_dialog_button_update")}
                </Button>
            </DialogActions>
        );
    };


    return (

        <Box sx={getBoxSx()}>
            {headline && (
                <Typography
                    variant="h1"
                    component="h1"
                    noWrap
                    sx={{
                        flexGrow: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        margin: 0,
                    }}
                >
                    {headline}
                </Typography>
            )}
            {filterEnabled && (

                <DateTimeRangePicker
                    slots={{
                        field: SingleInputDateTimeRangeField,
                        actionBar: customActionBar,
                    }}
                    maxDateTime={dayjs().endOf("day")}
                    slotProps={{
                        desktopPaper: {
                            sx: {
                                "& .MuiPickersLayout-root": {
                                    display: "flex",
                                    flexDirection: "column",
                                },
                                "& .MuiDialogActions-root": {
                                    order: 2,
                                },
                                "& .MuiPickersLayout-contentWrapper": {
                                    order: 1,
                                },
                            },
                        },
                        textField: {
                            sx: getFieldSx(),
                            InputProps: { endAdornment: <Calendar /> },
                        },

                    }}
                    label={t("datetime_picker_label")}
                    value={[dateTimeSelectedStart, dateTimeSelectedEnd]}
                    onAccept={(data) => {
                        updateDateTimes(data);
                    }}
                />

            )}
        </Box>
    );

};

export default HeadlineFilterComponent;
