import React, { useContext } from "react";
import { UserService } from "../services/UserService";

export type ServiceContextState = {
    userService: UserService;
};

const ServiceContext = React.createContext<ServiceContextState>({
    userService: new UserService(),
});

const useServiceContext = () => useContext(ServiceContext);

export { ServiceContext, useServiceContext };
