import { Autocomplete, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosAuthenticated from "../../hooks/useAxiosAuthenticated";
import { SysGroupPermissionModel } from "../../models/user/SysGroupPermissionModel";

interface SysGroupPermissionsFormComponentProps {
    onChangeHandlerPermissions: Function;
    required: boolean;
    defaultValue?: SysGroupPermissionModel[];
    setGeneralError: Function;
    urlBase: string;
}

export default function SysGroupPermissionsFormComponent(
    props: SysGroupPermissionsFormComponentProps,
) {
    const { t } = useTranslation();
    const [permissions, setPermissions] = useState<SysGroupPermissionModel[]>([]);
    const axiosAuthenticated = useAxiosAuthenticated();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                const response = await axiosAuthenticated.get(props.urlBase + "-permissions", {
                    signal: controller.signal,
                });
                isMounted && setPermissions(response.data);
            } catch (err) {
                props.setGeneralError();
            }
        };

        void getData();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosAuthenticated]);

    const getOptionValues = () => {
        let options: any[] = [];

        permissions.map((data) => {
            options.push(data);
        });

        return options;
    };

    const getDefaultValues = () => {
        let defaultValues: any[] = [];

        props.defaultValue?.map((data) => {
            defaultValues.push(data);
        });

        return defaultValues;
    };

    return (
        <Stack spacing={5}>
            <Autocomplete
                onChange={(event, value) => {
                    props.onChangeHandlerPermissions(value);
                }}
                multiple
                fullWidth
                groupBy={(option) =>
                    t("admin_user_edit_form_permission_label_" + option.display_data_type)
                }
                options={getOptionValues()}
                getOptionLabel={(option) =>
                    t(
                        "admin_user_edit_form_permission_label_with_prepend_type_" +
                            option.display_data_type,
                        { label: option.label },
                    )
                }
                defaultValue={getDefaultValues()}
                filterSelectedOptions={true}
                isOptionEqualToValue={(option: any, value: any) =>
                    option.data_id === value.data_id &&
                    option.data_type === value.data_type &&
                    option.label === value.label
                }
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.data_id}>
                            {t(
                                "admin_user_edit_form_permission_label_with_prepend_type_" +
                                    option.display_data_type,
                                { label: option.label },
                            )}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        required={props.required}
                        {...params}
                        label={t("admin_user_edit_form_permissions_label")}
                        placeholder={t("admin_user_edit_form_permissions_placeholder").toString()}
                        error={false}
                        margin="normal"
                    />
                )}
            />
        </Stack>
    );
}
