import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DialogComponent from "./DialogComponent";

interface ConfirmComponentProps {
    open: boolean;
    headline: string | ReactElement;
    content?: string;
    onYes: () => void;
    onNo: () => void;
    noButtonValue?: string;
    yesButtonValue?: string;
    onClose: () => void;
    hideButtons?: boolean;
    showYesLoader?: boolean;
}

export default function ConfirmComponent(props: ConfirmComponentProps) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const handleYes = () => {
        props.onYes && props.onYes();
    };

    const handleNo = () => {
        props.onNo && props.onNo();
    };

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    return (
        <DialogComponent open={open} onClose={handleClose}>
            <Box py={3} px={4}>
                <DialogTitle gutterBottom sx={{ marginTop: 0, paddingTop: 0, marginRight: 5 }}>
                    {props.headline}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 10,
                        top: 20,
                        color: (theme) => theme.palette.grey[500],
                        "&:hover": {
                            background: "none",
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {props.content && (
                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography gutterBottom>{props.content}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
                {!props.hideButtons && (
                    <DialogActions style={{ justifyContent: "space-between" }}>
                        <Button
                            color="primary"
                            variant={"outlined"}
                            onClick={handleNo}
                            sx={{
                                paddingLeft: 3,
                                paddingRight: 3,
                            }}
                        >
                            {props.noButtonValue
                                ? props.noButtonValue
                                : t("confirm_component_button_default_no")}
                        </Button>
                        <Button
                            variant={"contained"}
                            color="primary"
                            onClick={handleYes}
                            sx={{ paddingLeft: 5, paddingRight: 5 }}
                            disabled={props.showYesLoader}
                            startIcon={
                                props.showYesLoader ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {props.yesButtonValue
                                ? props.yesButtonValue
                                : t("confirm_component_button_default_yes")}
                        </Button>
                    </DialogActions>
                )}
            </Box>
        </DialogComponent>
    );
}
