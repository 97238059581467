export const colors = {
    defaultTheme: {
        bodyBackground: "#f8f8fe",
        primary: "#009fe3",
        textPrimary: "#232626",
        textSecondary: "#232626",
        textHeadlines: "#232626",
        tableBackground: "#ffffff",
        paperBackground: "#ffffff",
        contrastText: "#ffffff",
        green: "#8fcaaa",
    },
    // darkTheme: {
    //         bodyBackground: "#f8f8fe",
    //         primary: "#009fe3",
    //         textPrimary: "#232626",
    //         textSecondary: "#232626",
    //         textHeadlines: "#232626",
    //         tableBackground: "#ffffff",
    //         paperBackground: "#ffffff",
    //         contrastText: "#ffffff",
    //         green: "#8fcaaa",
    // },
};
