import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import FooterLinksComponent from "./FooterLinksComponent";

interface FooterGeneralComponentProps {}

export default function FooterGeneralComponent() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.main,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                py: { xs: 3 },
                textAlign: { sm: "center", md: "left" },
                borderTop: "1px solid",
                borderColor: "divider",
            }}
        >
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <FooterLinksComponent linkTextColor={"#fff"} />
            </Container>
        </Box>
    );
}
