import i18nCmp from "../I18n";

/**
 *
 * @param num
 * @param digits
 * @param unit
 * @param skip
 */
const convertNumber = (num: number, digits: number, unit: string = "", skip: number = -1) => {
    let si = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" },
            { value: 1e6, symbol: "M" },
            { value: 1e9, symbol: "G" },
            { value: 1e12, symbol: "T" },
            { value: 1e15, symbol: "P" },
            { value: 1e18, symbol: "E" },
        ],
        rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
        i;

    // Abkürzungen für Eurobeträge
    if (unit === "€") {
        si = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "T" },
            { value: 1e6, symbol: "Mio " },
            { value: 1e9, symbol: "Mrd " },
            { value: 1e12, symbol: "Bio " },
            { value: 1e15, symbol: "Brd " },
            { value: 1e18, symbol: "E" },
        ];
    }

    for (i = si.length - 1; i > 0; i--) {
        if (skip >= i) {
            continue;
        }

        if (num >= si[i].value) {
            break;
        }
    }

    return (
        i18nCmp
            .t("intlNumber", {
                val: num / si[i].value,
                precision: 2,
            })
            .replace(rx, "$1") +
        " " +
        si[i].symbol +
        unit
    );
};

const customCurrencyNumber = (num: number, currency: string) => {
    return i18nCmp.t("intlCurrency", {
        val: num,
        formatParams: {
            val: { currency: currency },
        },
    });
};

export { convertNumber, customCurrencyNumber };
