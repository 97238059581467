import DesktopHeaderComponent from "./Header/DesktopHeaderComponent";
import MobileHeaderComponent from "./Header/MobileHeaderComponent";

interface HeaderComponentProps {
    showMobileHeader: boolean;
}

const HeaderComponent = (props: HeaderComponentProps) => {
    return props.showMobileHeader ? <MobileHeaderComponent /> : <DesktopHeaderComponent />;
};

export default HeaderComponent;
