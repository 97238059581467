import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface FooterLinksComponentProps {
    linkTextColor: string;
}

export default function FooterLinksComponent(props: FooterLinksComponentProps) {
    const { t } = useTranslation();

    return (
        <div>
            <Link color={props.linkTextColor} href={t("url_imprint") as string} target={"_blank"}>
                {t("footer_imprint_And_TermsOfUse")}
            </Link>
            <Typography display="inline" sx={{ mx: 0.5 }} color={props.linkTextColor}>
                &nbsp;|&nbsp;
            </Typography>
            <Link
                color={props.linkTextColor}
                href={t("url_privacy_policy") as string}
                target={"_blank"}
            >
                {t("footer_privacy_policy")}
            </Link>
        </div>
    );
}
