import dayjs, { Dayjs } from "dayjs";
import i18nCmp from "../I18n";

const getLocalizedDate = (date: string | Dayjs): string => {
    if (typeof date === "string") {
        date = dayjs.utc(date);
    }
    return i18nCmp.t("intlDateTime", {
        val: date,
        formatParams: {
            val: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            },
        },
    });
};

const getLocalizedDateAndTime = (date: string | Dayjs): string => {
    if (typeof date === "string") {
        date = dayjs.utc(date);
    }

    return i18nCmp.t("intlDateTime", {
        val: date,
        formatParams: {
            val: {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
        },
    });
};

const getLocalizedTime = (date: string | Dayjs): string => {
    if (typeof date === "string") {
        date = dayjs.utc(date);
    }
    return i18nCmp.t("intlDateTime", {
        val: date,
        formatParams: {
            val: {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
            },
        },
    });
};

const getDateMonth = (date: string | Dayjs): string => {
    if (typeof date === "string") {
        date = dayjs.utc(date);
    }
    return i18nCmp.t("intlDateTime", {
        val: date,
        formatParams: {
            val: {
                year: "numeric",
                month: "2-digit",
            },
        },
    });
};

const formatDuration = (duration: number): string => {
    const minute = Math.floor(duration % 60);
    const hour = Math.floor(duration / 60);

    return `${hour} h ${minute} min`;
};

const ONE_SECOND = 1000;
const ONE_MINUTE = 1000 * 60;
const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = 1000 * 60 * 60 * 24;

export {
    formatDuration,
    getDateMonth,
    getLocalizedDate,
    getLocalizedDateAndTime,
    getLocalizedTime,
    ONE_DAY,
    ONE_HOUR,
    ONE_MINUTE,
    ONE_SECOND,
};
