import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBolt,
    faChargingStation,
    faEuroSign,
    faPlug,
    faPlugCircleBolt,
} from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { LicenseInfo } from "@mui/x-license";
import dayjs from "dayjs";
import "dayjs/locale/de";
import "dayjs/locale/en";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { closeSnackbar, SnackbarProvider } from "notistack";
import "./App.css";
import RoutesConfig from "./common/RoutesConfig";
import themes from "./common/Theme";
import AppProvider from "./providers/AppProvider";
import AuthProvider from "./providers/AuthProvider";
import ServiceProvider from "./providers/ServiceProvider";

LicenseInfo.setLicenseKey("0ec60245226ed104733f9ad3d7e48b2eTz0xMDY5NzYsRT0xNzY5OTAzOTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=");

dayjs.extend(utc);
dayjs.extend(timezone);

// FontAwesomeIcon muss hier eingebungen werden
library.add(faPlug, faPlugCircleBolt, faBolt, faEuroSign, faChargingStation);

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
            <AppProvider>
                <ThemeProvider theme={themes["default"]}>
                    <CssBaseline enableColorScheme={true} />
                    <AuthProvider>
                        <ServiceProvider>
                            <SnackbarProvider
                                preventDuplicate
                                maxSnack={3}
                                autoHideDuration={10000}
                                action={(snackbarId) => (
                                    <CloseIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => closeSnackbar(snackbarId)}
                                    />
                                )}
                            >
                                <RoutesConfig />
                            </SnackbarProvider>
                        </ServiceProvider>
                    </AuthProvider>
                </ThemeProvider>
            </AppProvider>
        </LocalizationProvider>
    );
}

export default App;
