import {
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_LV_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_LV_IDENTIFICATION_MEDIA_SINGLE,
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_WH_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_WH_IDENTIFICATION_MEDIA_SINGLE,
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_TRACES,
    VIEW_PERMISSION_DIAGRAM_ID_TAGS_TRACES_SINGLE,
    VIEW_PERMISSION_DIAGRAM_IDENTIFICATION_MEDIA_ALL_COMPANY_USER_REPORT,
    VIEW_PERMISSION_DIAGRAM_IDENTIFICATION_MEDIA_ALL_COMPANY_USER_REPORT_SINGLE,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
} from "../../models/ViewPermissionsModel";

const getRequiredViewPermissionToSeeIdentificationMediaDetailCharts = () => {
    return [
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_TRACES_SINGLE,
        VIEW_PERMISSION_DIAGRAM_IDENTIFICATION_MEDIA_ALL_COMPANY_USER_REPORT_SINGLE,
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_WH_IDENTIFICATION_MEDIA_SINGLE,
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_LV_IDENTIFICATION_MEDIA_SINGLE,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_IDENTIFICATION_MEDIA_SINGLE,
    ];
};

const hasSomeViewPermissionToSeeIdentificationMediaDetailCharts = (hasPermission: Function) => {
    return getRequiredViewPermissionToSeeIdentificationMediaDetailCharts().some((value) =>
        hasPermission(value),
    );
};

const getRequiredViewPermissionToSeeIdentificationMediaCharts = () => {
    return [
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_TRACES,
        VIEW_PERMISSION_DIAGRAM_IDENTIFICATION_MEDIA_ALL_COMPANY_USER_REPORT,
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_WH_IDENTIFICATION_MEDIA,
        VIEW_PERMISSION_DIAGRAM_ID_TAGS_PIE_LV_IDENTIFICATION_MEDIA,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_CHARGELOGS_ID_TAGS_IDENTIFICATION_MEDIA,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_LOADED_ENERGY_ID_TAGS_IDENTIFICATION_MEDIA,
        VIEW_PERMISSION_DIAGRAM_PIE_COUNT_OPERATING_LIFE_ID_TAGS_IDENTIFICATION_MEDIA,
    ];
};

const hasSomeViewPermissionToSeeIdentificationMediaCharts = (hasPermission: Function) => {
    return getRequiredViewPermissionToSeeIdentificationMediaCharts().some((value) =>
        hasPermission(value),
    );
};

export {
    getRequiredViewPermissionToSeeIdentificationMediaCharts,
    getRequiredViewPermissionToSeeIdentificationMediaDetailCharts,
    hasSomeViewPermissionToSeeIdentificationMediaCharts,
    hasSomeViewPermissionToSeeIdentificationMediaDetailCharts,
};
