import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Badge, IconButton, SxProps, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

interface NotificationIconComponentProps {
    NotificationIconSX: SxProps | undefined;
}

const NotificationIconComponent = (props: NotificationIconComponentProps) => {
    const { unreadNotificationsAmount } = useAuth();
    const theme = useTheme();

    return (
        <Link to={"/benachrichtigungen"}>
            <IconButton sx={props.NotificationIconSX}>
                <Badge
                    badgeContent={unreadNotificationsAmount}
                    color="secondary"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    {unreadNotificationsAmount > 0 ? (
                        <NotificationsActiveIcon sx={{ color: theme.palette.primary.main }} />
                    ) : (
                        <NotificationsIcon sx={{ color: theme.palette.secondary.main }} />
                    )}
                </Badge>
            </IconButton>
        </Link>
    );
};

export default NotificationIconComponent;
