import { Autocomplete, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxiosAuthenticated from "../../hooks/useAxiosAuthenticated";
import { SysUserModel } from "../../models/user/SysUserModel";

interface UserSelectFormComponentProps {
    required: boolean;
    defaultValue: SysUserModel[];
    onChangeHandlerSysUsers: Function;
    setGeneralError: Function;
}

export default function UserSelectFormComponent(props: UserSelectFormComponentProps) {
    const { t } = useTranslation();
    const [sysUsers, setSysUsers] = useState<SysUserModel[]>([]);
    const axiosAuthenticated = useAxiosAuthenticated();

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getData = async () => {
            try {
                const response = await axiosAuthenticated.get("/admin/user", {
                    signal: controller.signal,
                });
                isMounted && setSysUsers(response.data);
            } catch (err) {
                props.setGeneralError();
            }
        };

        void getData();

        return () => {
            isMounted = false;
            controller.abort();
        };
    }, [axiosAuthenticated]);

    const getOptionValues = () => {
        let options: any[] = [];

        sysUsers.map((data) => {
            options.push(data);
        });

        return options;
    };

    const getDefaultValues = () => {
        let defaultValues: any[] = [];

        props.defaultValue.map((data) => {
            defaultValues.push(data);
        });

        return defaultValues;
    };

    return (
        <Stack spacing={5}>
            <Autocomplete
                onChange={(event, value) => {
                    props.onChangeHandlerSysUsers(value);
                }}
                multiple
                fullWidth
                options={getOptionValues()}
                getOptionLabel={(option) => option.name + " (" + option.cred_usr + ")"}
                defaultValue={getDefaultValues()}
                filterSelectedOptions={true}
                isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id &&
                    option.cred_usr === value.cred_usr &&
                    option.name === value.name
                }
                renderOption={(props, option: any) => {
                    return (
                        <li {...props} key={option.id} value={option.id}>
                            {option.name + " (" + option.cred_usr + ")"}
                        </li>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        required={props.required}
                        {...params}
                        label={t("admin_user_select_input_label")}
                        placeholder={t("admin_user_select_input_placeholder").toString()}
                        error={false}
                        margin="normal"
                    />
                )}
            />
        </Stack>
    );
}
