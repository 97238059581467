import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";

export type AppContextState = {
    dateTimeSelectedStart: Dayjs | null;
    dateTimeSelectedEnd: Dayjs | null;
    clientTimezone: string;
    updateDateTimesSelected: (data: DateRange<dayjs.Dayjs>) => void;
    resetDateTimesSelected: () => void;
    getStandardDateStart: () => dayjs.Dayjs | void;
    getStandardDateEnd: () => dayjs.Dayjs | void;
    headline: string | null;
    setHeadline: (headline: string | null) => void;
    filterEnabled: boolean;
    setFilterEnabled: (enabled: boolean) => void;
};

const AppContext = React.createContext<AppContextState>({
    dateTimeSelectedStart: null,
    dateTimeSelectedEnd: null,
    clientTimezone: "Europe/Berlin",
    filterEnabled: true,
    setFilterEnabled: () => {
    },
    updateDateTimesSelected: () => {
    },
    resetDateTimesSelected: () => {
    },
    getStandardDateStart: () => {

    },
    getStandardDateEnd: () => {

    },
    headline: null,
    setHeadline: () => {
    },
});

const useAppContext = () => useContext(AppContext);

export { AppContext, useAppContext };
