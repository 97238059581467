import { Alert, CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PaperItemComponent } from "../../../common/components/PaperItemComponent";
import GroupEditFormComponent, {
    SysGroupFormValues,
} from "../../../components/admin/GroupEditFormComponent";
import SysGroupPermissionsFormComponent
    from "../../../components/admin/SysGroupPermissionsFormComponent";
import UserSelectFormComponent from "../../../components/admin/UserSelectFormComponent";
import { useAppContext } from "../../../context/AppContext";
import useAxiosAuthenticated from "../../../hooks/useAxiosAuthenticated";
import {
    GROUP_OVERVIEW_TYPE_DATA,
    GROUP_OVERVIEW_TYPE_VIEW,
    SysGroupModel,
} from "../../../models/user/SysGroupModel";

interface AdminGroupEditFormData {
    sys_group: SysGroupModel;
}

interface GroupEditComponentProps {
    type: number;
}

export default function GroupEditComponent(props: GroupEditComponentProps) {
    const { groupId } = useParams();
    const { t } = useTranslation();
    const [formDefaultValues, setFormDefaultValues] = useState<AdminGroupEditFormData | null>(null);
    const [formValues, setFormValues] = useState<SysGroupModel>();
    const [errorMessageInitialize, setErrorMessageInitialize] = useState<boolean>(false);
    const [isSending, setIsSending] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState<any>({});
    const axiosAuthenticated = useAxiosAuthenticated();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const navigate = useNavigate();
    const { setHeadline } = useAppContext();

    useEffect(() => {
        if (formDefaultValues?.sys_group) {
            setHeadline(t("admin_group_headline_edit_" + props.type, {
                name: formDefaultValues.sys_group.name,
            }));
        } else {
            setHeadline(t("admin_group_headline_create_" + props.type));
        }

    }, [setHeadline, formDefaultValues, t, props.type]);


    useEffect(() => {
        let isMounted = true;
        if (groupId) {
            setErrorMessageInitialize(false);
            const controller = new AbortController();

            const getData = async () => {
                try {
                    const response = await axiosAuthenticated.get(getUrlBase() + "/" + groupId, {
                        signal: controller.signal,
                    });
                    isMounted &&
                    setFormDefaultValues({ ...formDefaultValues, sys_group: response.data });
                    setFormValues(response.data);
                    setIsEditMode(true);
                } catch (err) {
                    setErrorMessageInitialize(true);
                }
            };

            void getData();

            return () => {
                isMounted = false;
                controller.abort();
            };
        } else {
            setFormDefaultValues(null);
        }

        return () => {
            isMounted = false;
        };
    }, [groupId]);

    const handleSubmit = (e: React.SyntheticEvent) => {
        setFormErrors({});
        e.preventDefault();

        if (isSending) {
            return;
        }

        setIsSending(true);

        const save = async () => {
            let url = getUrlBase();
            if (isEditMode) {
                url = url + "/" + groupId;
            }

            try {
                await axiosAuthenticated({
                    method: isEditMode ? "patch" : "post",
                    url: url,
                    data: formValues,
                }).then((response) => {
                    enqueueSnackbar(
                        t("admin_group_save_success_" + props.type + "_" + isEditMode),
                        {
                            variant: "success",
                        },
                    );

                    setIsSending(false);

                    if (!isEditMode) {
                        navigate(getUrlBase() + "/edit/" + response.data.id);
                    }
                });
            } catch (error: any) {
                if (error.response.status === 400) {
                    setFormErrors(error.response.data);
                    setIsSending(false);
                } else {
                    enqueueSnackbar(t("admin_group_save_error_general"), {
                        variant: "error",
                    });
                }
            }
        };

        void save();
    };

    const getUrlBase = () => {
        let urlBase = "";

        if (props.type === GROUP_OVERVIEW_TYPE_DATA) {
            urlBase = "/admin/group";
        } else {
            urlBase = "/admin/view-group";
        }
        return urlBase;
    };

    return (
        <>
            {errorMessageInitialize ? (
                <>
                    <Grid xs={12} item mb={4} mt={4}>
                        <Alert severity="error">{t("errorReloadPage")}</Alert>
                    </Grid>
                </>
            ) : (

                <>
                    <form method="post" onSubmit={handleSubmit}>
                        <Grid item xs={12} lg={6} container spacing={3}>
                            <Grid item xs={12}>
                                <>
                                    {formDefaultValues || !groupId ? (
                                        <PaperItemComponent sx={{ textAlign: "left" }}>
                                            <GroupEditFormComponent
                                                defaultValue={
                                                    formDefaultValues?.sys_group as SysGroupFormValues
                                                }
                                                inputsRequired={true}
                                                onChangeHandlerGroupName={(data: string) => {
                                                    setFormValues((prev: any) => {
                                                        if (prev) {
                                                            return { ...prev, name: data };
                                                        }
                                                        return { name: data };
                                                    });
                                                }}
                                                onChangeHandlerGroupDescription={(
                                                    data: string,
                                                ) => {
                                                    setFormValues((prev: any) => {
                                                        if (prev) {
                                                            return {
                                                                ...prev,
                                                                description: data,
                                                            };
                                                        }
                                                        return { description: data };
                                                    });
                                                }}
                                                onChangeHandlerGroupType={(data: any) => {
                                                    setFormValues((prev: any) => {
                                                        if (prev) {
                                                            return { ...prev, type: data };
                                                        }
                                                        return { type: data };
                                                    });
                                                }}
                                                errorGroupName={
                                                    formErrors.name
                                                        ? formErrors.name.join()
                                                        : null
                                                }
                                                errorGroupDescription={
                                                    formErrors.description
                                                        ? formErrors.description.join()
                                                        : null
                                                }
                                                errorGroupType={
                                                    formErrors.name
                                                        ? formErrors.name.join()
                                                        : null
                                                }
                                                setGeneralError={() =>
                                                    setErrorMessageInitialize(true)
                                                }
                                                urlBase={getUrlBase()}
                                                type={props.type}
                                            />
                                            <UserSelectFormComponent
                                                required={
                                                    formValues?.sys_users
                                                        ? formValues?.sys_users?.length === 0
                                                        : true
                                                }
                                                defaultValue={
                                                    formDefaultValues?.sys_group?.sys_users ??
                                                    []
                                                }
                                                onChangeHandlerSysUsers={(data: any) => {
                                                    setFormValues((prev: any) => {
                                                        if (prev) {
                                                            return { ...prev, sys_users: data };
                                                        }
                                                        return { sys_users: data };
                                                    });
                                                }}
                                                setGeneralError={() =>
                                                    setErrorMessageInitialize(true)
                                                }
                                            />
                                            {(isEditMode &&
                                                formValues?.is_superadmin_group === false) ||
                                            (isEditMode &&
                                                formValues?.type ===
                                                GROUP_OVERVIEW_TYPE_VIEW) ||
                                            !isEditMode ? (
                                                <SysGroupPermissionsFormComponent
                                                    required={
                                                        formValues?.sys_group_permissions
                                                            ? formValues?.sys_group_permissions
                                                            ?.length === 0
                                                            : true
                                                    }
                                                    defaultValue={
                                                        formDefaultValues?.sys_group
                                                            ?.sys_group_permissions ?? []
                                                    }
                                                    onChangeHandlerPermissions={(data: any) => {
                                                        setFormValues((prev: any) => {
                                                            if (prev) {
                                                                return {
                                                                    ...prev,
                                                                    sys_group_permissions: data,
                                                                };
                                                            }
                                                            return {
                                                                sys_group_permissions: data,
                                                            };
                                                        });
                                                    }}
                                                    setGeneralError={() =>
                                                        setErrorMessageInitialize(true)
                                                    }
                                                    urlBase={getUrlBase()}
                                                />
                                            ) : (
                                                <Alert severity={"error"} sx={{ marginTop: 5 }}>
                                                    {t("admin_group_super_admin_hint")}
                                                </Alert>
                                            )}
                                        </PaperItemComponent>
                                    ) : (
                                        <PaperItemComponent>
                                            <CircularProgress color="primary" />
                                        </PaperItemComponent>
                                    )}
                                </>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "left" }} mt={3}>
                                    <Link to={getUrlBase()}>
                                        <Button
                                            disabled={false}
                                            variant="outlined"
                                            color={"secondary"}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: "right" }} mt={3}>
                                    <Button
                                        disabled={isSending}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {formDefaultValues?.sys_group
                                            ? t(
                                                "admin_group_edit_form_submit_button_value_edit_" +
                                                props.type,
                                            )
                                            : t(
                                                "admin_group_edit_form_submit_button_value_create_" +
                                                props.type,
                                            )}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </>
            )}
        </>
    );
}
