import { ReactNode, useState } from "react";
import { AuthContext, AuthContextState, User } from "../context/AuthContext";

interface AuthProviderProps {
    children?: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [user, setUser] = useState<User | null>(null);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [permissions, setPermissions] = useState<string[]>([]);
    const [isDone, setIsDone] = useState<boolean>(false);
    const [unreadNotificationsAmount, setUnreadNotificationsAmount] = useState<number>(0);

    const handleLogin = async (user: User, accessToken: string) => {
        setUser(user);
        setAccessToken(accessToken);
        setPermissions(user.permissions);
    };

    const handleLogout = () => {
        setUser(null);
        setAccessToken(null);
        setPermissions([]);
        return true;
    };

    const hasPermission = (requiredPermission: string) => {
        if (user) {
            return permissions.includes(requiredPermission);
        }
        return false;
    };

    const value: AuthContextState = {
        accessToken,
        setAccessToken,
        user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        setUser,
        setPermissions,
        hasPermission: hasPermission,
        setIsDone: setIsDone,
        isDone: isDone,
        unreadNotificationsAmount,
        setUnreadNotificationsAmount,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
