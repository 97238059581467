import {
    VIEW_PERMISSION_ADMIN_MANAGE_VEHICLE_DATA,
    VIEW_PERMISSION_CHARGING_STATIONS_WITH_MAP,
    VIEW_PERMISSION_EXPORTS,
    VIEW_PERMISSION_IDENTIFICATION_MEDIA,
    VIEW_PERMISSION_IDENTIFICATION_MEDIA_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_CHARGEPOINT_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_LOCATIONS_DATA_AVAILABLE,
    VIEW_PERMISSION_INFRASTRUCTURE_STATISTICS_DATA_AVAILABLE,
    VIEW_PERMISSION_LOCATION_ACCESS,
    VIEW_PERMISSION_MANAGE_GROUPS,
    VIEW_PERMISSION_MANAGE_LOGINS,
    VIEW_PERMISSION_MANAGE_VIEW_GROUPS,
    VIEW_PERMISSION_OCPP_SETTINGS_EDIT,
    VIEW_PERMISSION_PHONE_CALL,
    VIEW_PERMISSION_PLAUSI_CHECK_CHARGELOGS,
} from "../../models/ViewPermissionsModel";
import { hasSomeViewPermissionToSeeIdentificationMediaCharts } from "./ChartsHelper";

export type NavElement = {
    path?: string;
    translateKeyNavPoint: string;
    requiredPermission?: string;
    subEntries?: NavElement[];
    icon?: string;
};

const getMainNavElements = (hasPermission: Function) => {
    const navElements: NavElement[] = [];
    const navElementsAdmin: NavElement[] = [];
    const navElementsInfrastructure: NavElement[] = [];
    const navElementsIdentificationMedia: NavElement[] = [];

    navElements.push({
        path: "/",
        translateKeyNavPoint: "nav_homepage",
    });

    if (hasPermission(VIEW_PERMISSION_INFRASTRUCTURE_STATISTICS_DATA_AVAILABLE)) {
        navElementsInfrastructure.push({
            path: "/infrastruktur/statistiken",
            translateKeyNavPoint: "nav_infrastructure_statistics",
        });
    }

    if (
        hasPermission(VIEW_PERMISSION_LOCATION_ACCESS) &&
        hasPermission(VIEW_PERMISSION_INFRASTRUCTURE_LOCATIONS_DATA_AVAILABLE)
    ) {
        navElementsInfrastructure.push({
            path: "/infrastruktur/standorte",
            translateKeyNavPoint: "nav_infrastructure_locations",
        });
    }

    if (
        hasPermission(VIEW_PERMISSION_CHARGING_STATIONS_WITH_MAP) &&
        hasPermission(VIEW_PERMISSION_INFRASTRUCTURE_CHARGEPOINT_DATA_AVAILABLE)
    ) {
        navElementsInfrastructure.push({
            path: "/infrastruktur/ladestationen",
            translateKeyNavPoint: "nav_infrastructure_charging_stations",
        });
    }

    if (navElementsInfrastructure.length > 0) {
        navElements.push({
            translateKeyNavPoint: "nav_infrastructure",
            subEntries: navElementsInfrastructure,
        });
    }

    // Handling Navigationselement "Lademedien"
    if (
        hasPermission(VIEW_PERMISSION_IDENTIFICATION_MEDIA) &&
        hasPermission(VIEW_PERMISSION_IDENTIFICATION_MEDIA_DATA_AVAILABLE)
    ) {
        const hasViewPermissionToSeeIdentificationMediaCharts =
            hasSomeViewPermissionToSeeIdentificationMediaCharts(hasPermission);

        // Es gibt keine Statistiken zur Anzeige, somit zeigen wir kein Submenü an
        if (!hasViewPermissionToSeeIdentificationMediaCharts) {
            navElements.push({
                path: "/lademedien",
                translateKeyNavPoint: "nav_identification_media",
            });
        } else {
            navElementsIdentificationMedia.push({
                path: "/lademedien",
                translateKeyNavPoint: "nav_overview_identification_media",
            });

            navElementsIdentificationMedia.push({
                path: "/lademedien/statistiken",
                translateKeyNavPoint: "nav_identification_media_statistics",
            });

            navElements.push({
                translateKeyNavPoint: "nav_identification_media",
                subEntries: navElementsIdentificationMedia,
            });
        }
    }

    navElements.push({
        path: "/ladevorgaenge",
        translateKeyNavPoint: "nav_chargelogs",
    });

    if (hasPermission(VIEW_PERMISSION_MANAGE_LOGINS)) {
        navElementsAdmin.push({
            path: "/admin/login",
            translateKeyNavPoint: "admin_user_overview_headline",
        });
    }

    if (hasPermission(VIEW_PERMISSION_MANAGE_GROUPS)) {
        navElementsAdmin.push({
            path: "/admin/group",
            translateKeyNavPoint: "admin_group_overview_headline_1",
        });
    }

    if (hasPermission(VIEW_PERMISSION_MANAGE_VIEW_GROUPS)) {
        navElementsAdmin.push({
            path: "/admin/view-group",
            translateKeyNavPoint: "admin_group_overview_headline_2",
        });
    }

    if (hasPermission(VIEW_PERMISSION_OCPP_SETTINGS_EDIT)) {
        navElementsAdmin.push({
            path: "/admin/ocpp-vorlagen",
            translateKeyNavPoint: "admin_ocpp_template_overview_headline",
        });
    }

    if (hasPermission(VIEW_PERMISSION_PLAUSI_CHECK_CHARGELOGS)) {
        navElementsAdmin.push({
            path: "/admin/lv-plausi-checks",
            translateKeyNavPoint: "admin_plausi_checks_chargelogs_nav",
        });
    }

    if (hasPermission(VIEW_PERMISSION_ADMIN_MANAGE_VEHICLE_DATA)) {
        navElementsAdmin.push({
            path: "admin/fahrzeugdaten-verwalten",
            translateKeyNavPoint: "admin_manage_vehicle_data_nav",
        });
    }

    if (hasPermission(VIEW_PERMISSION_PHONE_CALL)) {
        navElementsAdmin.push({
            path: "admin/anrufe",
            translateKeyNavPoint: "admin_phone_call_nav",
        });
    }

    if (navElementsAdmin.length > 0) {
        navElements.push({
            translateKeyNavPoint: "nav_admin",
            subEntries: navElementsAdmin,
        });
    }

    return navElements;
};

const getSettingsMenuNavElements = (hasPermission: Function) => {
    const navElements: NavElement[] = [];

    navElements.push({
        path: "/user/einstellungen",
        translateKeyNavPoint: "nav_settings",
        icon: "settings",
    });

    if (hasPermission(VIEW_PERMISSION_EXPORTS)) {
        navElements.push({
            path: "/user/export-downloads",
            translateKeyNavPoint: "nav_export_downloads",
            icon: "download",
        });
    }

    navElements.push({
        path: "/user/logout",
        translateKeyNavPoint: "nav_logout",
        icon: "logout",
    });

    return navElements;
};

export { getMainNavElements, getSettingsMenuNavElements };
