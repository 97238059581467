import { Dispatch, SetStateAction, createContext } from "react";

export type User = {
    user: string;
    userId: number;
    userLogo?: string;
    username: string;
    permissions: string[];
    language: string | null;
};

export type AuthContextState = {
    accessToken: string | null;
    user: User | null;
    onLogin: (user: User, accessToken: string) => void;
    onLogout: () => boolean | void;
    setAccessToken: (prev: any) => void;
    setUser: (prev: User) => void;
    hasPermission: (requiredPermission: string) => boolean;
    setPermissions: (prev: string[]) => void;
    isDone: boolean;
    setIsDone: (prev: boolean) => void;
    unreadNotificationsAmount: number;
    setUnreadNotificationsAmount: Dispatch<SetStateAction<number>>;
};

const AuthContext = createContext<AuthContextState>({
    accessToken: null,
    user: null,
    onLogin: () => {},
    onLogout: () => {},
    setAccessToken: () => {},
    setUser: () => {},
    setPermissions: () => {},
    hasPermission: (requiredPermission) => {
        return false;
    },
    isDone: false,
    setIsDone: () => {},
    unreadNotificationsAmount: 0,
    setUnreadNotificationsAmount: () => {},
});

export { AuthContext };
